
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import WeeklyAvailabilityModal from "@/components/Modals/WeeklyAvailabilityModal.vue";
import AgendaItemsLoading from "@/components/AgendaItemsLoading.vue";
import AgendaTimezoneModal from "@/components/Modals/AgendaTimezoneModal.vue";
import AgendaItemContainer from "@/components/AgendaItemContainer.vue";
import AgendaItemWeekContainer from "@/components/AgendaItemWeekContainer.vue";

export default Vue.extend({
  name: "AgendaPage",
  components: {
    WeeklyAvailabilityModal,
    AgendaItemContainer,
    AgendaItemWeekContainer,
    AgendaItemsLoading,
    AgendaTimezoneModal
  },
  data() {
    return {
      date: moment().format("YYYY-MM-DD"),
      loading: true,
      currentTime: {},
      data: {
        view_by: "week"
      },
      viewOptions: [
        { id: "day", name: "Day" },
        { id: "week", name: "Week" }
      ],
      dateTime: "",
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      }
    };
  },
  computed: {
    ...mapGetters({
      agenda: "agenda/getAgenda",
      profile: "profile/getProfile"
    }),
    getDate: function() {
      const today = moment().format("YYYY-MM-DD");
      const todayDate = moment(today, "YYYY-MM-DD");
      //@ts-ignore
      const pastDate = moment(this.date, "YYYY-MM-DD");
      //@ts-ignore
      return todayDate.isBefore(pastDate) ? this.date : today;
    } as any
  },
  watch: {
    date(val) {
      if (val !== "") {
        this.getAgenda();
      }
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.manageAgenda === "true") {
      this.$bvModal.show("weekly-availability");
    }
    this.countdown();
    this.getAgenda();
  },
  methods: {
    nextOrPrevMonth(type: string) {
      const amount = type === "next" ? 1 : -1;
      this.date = moment(this.date)
        .add(amount, "day")
        .format("YYYY-MM-DD");
    },
    getAgenda() {
      this.loading = true;
      this.$store
        .dispatch("agenda/GET_AGENDA", {
          date: this.date,
          view: this.data.view_by
        })
        .then(() => (this.loading = false));
    },
    countdown() {
      const zone = this.profile?.timezone_format || "UTC";
      this.dateTime = moment()
        .tz(zone)
        .format("LT")
        .toString();
      setTimeout(() => this.countdown(), 1000);
    },
    setDate(date: string) {
      this.data.view_by = "day";
      if (this.date == date) {
        this.getAgenda();
      } else {
        this.date = date;
      }
    },
    getFormattedDate(date: string) {
      return moment(date, "YYYY-MM-DD")
        .format("dddd, DD/MM/yyyy")
        .toString();
    }
  }
});
